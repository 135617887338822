<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>

  <div class="see_detail_package text-dark" v-if="seeDetailPackage">
    <div class="card">
      <div class="form-body">
        <div class="card-body">
          <h1>Détail du package</h1>
         
          <h3> <span v-if="nameOfPackage.franchise == 0">Package</span> 
            <span v-else>Franchise</span>
             : {{nameOfPackage.libelle}}
            </h3>   
          <div class="row pt-3">
              <div class="col-md-12 detail_packages">
               <h5>Libellé : <span> {{nameOfPackage.libelle}}</span></h5>
               <h5>Achat par piece : <span>{{moneyFormat.format(nameOfPackage.cout_acquisition)}} Fcfa </span></h5>
               <h5>Gain par piece : <span>{{moneyFormat.format(nameOfPackage.gain_par_piece)}} Fcfa </span></h5>
               <h5>Prix total de vente : <span>{{moneyFormat.format(nameOfPackage.cout_vente * nameOfPackage.nb_products)}} Fcfa</span></h5>
               <h5>Délai d'ecoulement : <span>{{nameOfPackage.nb_jours}} Jours</span></h5>
               <h5>Revenu : <span> {{nameOfPackage.income_type}}</span></h5>
               <h5>Stock initial : <span>{{nameOfPackage.nb_products}} pièces </span></h5>
               <h5>Stock restant : <span>{{nameOfPackage.pieces_restantes}} pièces </span></h5>
               <h5 v-if="nameOfPackage.franchise == 0">Par lot de : <span>{{nameOfPackage.tranche}} </span></h5> 
              </div>
              <div class="col-md-12">
                <table>
                  <thead>
                    <th>Date</th>
                    <th>Stock Ajouté</th>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in listForPackageAdd" :key="index">
                      <td>{{new Date(item.created_at).toLocaleDateString('fr')}}</td>
                      <td>{{item.qte}}</td>
                    </tr>
                  </tbody>
                </table>
               </div>
          </div>
        </div>      
          <div class="card-body">
            <button @click="seeDetailPackage = !seeDetailPackage" type="button" class="btn btn-dark">
              Retour
            </button>
        </div>
    
      </div>
    </div>
  </div>

  <div class="confirmation_modifie text-dark" v-if="windowForAddPackage">
    <div class="card">
      <div class="form-body">
        <form  @submit.prevent="addNumberAtPackage">
       
        <div class="card-body">
          <h1>Ajouter un stock</h1>
          <h3>Package : {{this.nameOfPackage}}</h3>
          <span>Stock restant : {{this.stockRestant}} (lot de : {{this.nb_part}})</span>         
          <div class="row pt-3">

              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label fw-bold">Nombre de pièces</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="quantityOfPackage"
                    required
                    pattern="^([0-9]*)$"
                    @input.prevent="isNumberIsPaire"
                  />
                </div>
              </div>
            <!--/span-->
          </div>
        </div>      
          <div class="card-body">
            <button
              type="submit"
              class="btn text-white mx-3 btn-envoyer"
             :disabled="
                type_id === null
              ">
              Envoyer
            </button>
            <button @click="windowForAddPackage = !windowForAddPackage" type="button" class="btn btn-dark">
              Annuler
            </button>
        </div>
        </form>
      </div>
    </div>
  </div>
 
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span>Mes packages</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="row container-fluid">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">Nom</label>
            <input
              type="text"
              class="form-control form-control-danger"
              v-model="user.nom"
              disabled
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">Prénoms</label>
            <input
              type="text"
              class="form-control form-control-danger"
              v-model="user.prenoms"
              disabled
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">email</label>
            <input
              type="text"
              class="form-control form-control-danger"
              v-model="user.email"
              disabled
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">Télephone</label>
            <input
              type="text"
              class="form-control form-control-danger"
              v-model="user.phone"
              disabled
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">Solde</label>
            <input
              type="text"
              class="form-control form-control-danger"
              v-model="currency"
              disabled
            />
          </div>
        </div>
      </div>
     
      <div class="created_package mt-2">
        <button
          class="btn btn-lg bouton-create-type-package text-light fw-bold"
          @click="addPackage"
        >
          Créer un package
        </button>
      </div>
      <div class="col-md-12">
       
        <div>
          <table id="MyTableData" class="table" v-if="this.packages !== null">
            <thead>
              <tr>
                
                <th class="bg-light">Package</th>
                 <th class="bg-light">Image</th>
                <th class="bg-light">Achat par pièce (Fcfa)</th>
                <th class="bg-light">Vente par pièces (Fcfa)</th>
                <th class="bg-light">Gain par pièce (Fcfa)</th>
                <th class="bg-light">Délai d'ecoulement (jours)</th>
                <th class="bg-light">Revenu</th>
                <th class="bg-light">Stock initial</th>
                
               
                <th class="bg-light">Stock restant</th>
                <th class="bg-light">Etat</th>
                <th class="bg-light text-right">Date d'enregistrement</th>
                <th class="bg-light text-right">Détails</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in packages" :key="index">
              
                <td>
                  {{ item.libelle }}
                </td>
                <td>
                  <n-image
                  v-if="item.photo"
                  width="100"
                  :src="'https://backend.invest-ci.com/public'+ item.photo"
                  :alt="user.photo"
                />
                <span v-else class="badge bg-danger">x</span>
                </td>
                <td>{{ moneyFormat.format(item.cout_acquisition) }}</td>
                <td>{{ moneyFormat.format(item.cout_vente) }} </td>
                <td>{{ moneyFormat.format(item.gain_par_piece) }}</td>
                <td>
                  
                  <span v-if="item.nb_jours">{{ item.nb_jours }}</span>
                  <span v-else class="badge bg-danger">x</span>
                </td>
                <td>{{ item.income_type }}</td>
                <td>{{ item.nb_products }}</td>
              
                <td>{{ item.pieces_restantes }}</td>
                <td>
                  <span
                    v-if="item.etat == 'en cours de traitement'"
                    class="badge bg-dark bg-gradient"
                    >En cours de traitement</span
                  >
                  <span
                    v-if="item.etat == 'publie'"
                    class="badge bg-success bg-gradient"
                    >Publié</span
                  >
                  <span
                    v-if="item.etat == 'rejete'"
                    class="position-relative verif badge bg-danger bg-gradient"
                  >
                    Rejeter</span
                  >
                </td>
                <td>
                  {{ new Date(item.created_at).toLocaleDateString("fr") }}
                </td>

                <td class="text-right">
                  <div
                    class="dropdown dropdown-action d-flex justify-content-center align-self-center"
                  >
                    <router-link
                      v-if="item.etat == 'rejete' || item.etat == 'en cours de traitement'"
                      title="Detail Package"
                      :to="{
                        name: 'ModifierPackageRejete',
                        params: { id: item.id },
                      }"
                      class="btn boutons m-1 bg-pen text-light"
                    >
                      <i class="bi bi-pencil-fill"></i>
                    </router-link>
                 <button class="btn btn-lg m-1 boutons bg-warning text-light" @click="seeAllDetailPackage(item.id)" >
                  <i class="bi bi-eye " ></i>
                 </button>
                  
                  <button class="bg-dark text-light" @click="seePackageForModify(item.id)" v-if="
                  item.etat == 'publie' && item.pieces_restantes > 0">Ajouter</button>
                  <!-- <span
                  v-if="item.etat == 'publie' 
                  && item.pieces_restantes == 0
                  ">x</span> -->
                    <button
                      v-if="
                        item.etat == 'en cours de traitement' ||
                        item.etat == 'rejete'
                      "
                      @click="show(item.id)"
                      title="Supprimer l' utilisateur"
                      class="btn btn-lg m-1 boutons bg-danger text-light"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="delete_personne" v-show="showMsg">
      <div class="delete">
        <span class="fw-bold">vous-voulez vraiment supprimer l'article?</span>
        <button
          class="btn btn-lg bg-pen text-light my-3 border-0 rounded p-2 fw-bold"
          @click="delete_article"
        >
          Supprimer
        </button>
        <button
          class="btn btn-lg bg-dark text-light border-0 rounded p-2 fw-bold"
          @click="fermer"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
  
</template>
<script>
import Swal from "sweetalert2";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { lien } from "/src/assets/api.js";
import axios from "axios";
export default {
  name: "VoirPackageFournisseurs",
  components: {

    Loading,
  },
  data() {
    return {
      user: null,
      onCancel:"",
      fullPage:"",
      type_id:"",
      windowForAddPackage:false,
      packages: null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      isLoading: false,
      seeDetailPackage:false,
      currency: null,
      showMsg: false,
      id_delete: null,
     quantityOfPackage:"",
     idPackage:"",
     nameOfPackage:"",
     stockRestant:"",
     sommeStock:'',
     nb_part:"",
     listForPackageAdd:[]
    };
  },
  methods: {
    isNumberIsPaire(e){
      this.sommeStock = Number(this.stockRestant)+ Number(e.target.value)
      //console.log(Number(this.stockRestant)+ Number(e.target.value))
    },
    seeAllDetailPackage(id){
      axios.get(lien+"/api/addsbypack/"+id)
      .then(response=>{
        //console.log("seeAllDetailPackage",response)
        this.listForPackageAdd = response.data.data
      })
      .catch(error=>{
        console.log(error)
      })
      this.packages.forEach(item=>{
        if(item.id == id){
          this.nameOfPackage = item
          this.idPackage = item.id
          this.stockRestant = item.pieces_restantes
          this.nb_part = item.tranche
        }
      })
      this.seeDetailPackage = !this.seeDetailPackage
    },
    seePackageForModify(id){
      //console.log('ELEMENT',id)
      this.packages.forEach(item=>{
        if(item.id == id){
          this.nameOfPackage = item.libelle
          this.idPackage = item.id
          this.stockRestant = item.pieces_restantes
          this.nb_part = item.tranche
        }
      })
      this.windowForAddPackage = !this.windowForAddPackage
    },
    addNumberAtPackage(){
      this.spinner = true
      if(this.sommeStock % this.nb_part == 0){

        axios.post(lien+"/api/ajouts",{
        qte:this.quantityOfPackage,
        package_id:this.idPackage
         })
       .then(reponse =>{
        
        if(reponse.data.status == 'true' || reponse.data.status === true){
             Swal.fire({
              text: "Enregistre avec succès.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            })
            this.nb_vente =" "
            this.type_id =" "
          this.spinner = false
          this.quantityOfPackage = ""
          
          this.windowForAddPackage = !this.windowForAddPackage
        }
        if(reponse.data.status === false || reponse.data.status == 'false'){
          Swal.fire({
              text: reponse.data.message,
              icon: "info",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            })
            this.spinner = false
        }
    })
    .catch(error=>{
        console.log(error);
           Swal.fire({
              text: "Contactez votre service informatique",
              icon: "info",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
            this.spinner = false
    })

      }else{
        Swal.fire({
          text: "le nombre de lot choisi doit etre divisible par le nombre de produits.",
          icon: "info",
          showConfirmButton: true,
         
        });
      }
    
    },
    addPackage() {
      this.$router.push("/package");
    },
    show(id) {
      this.showMsg = !this.showMsg;
      this.id_delete = id;
      //console.log("ID A DELETE", this.id_delete);
    },
    fermer() {
      this.showMsg = !this.showMsg;
      this.id_delete = null;
      //console.log("ID A DELETE", this.id_delete);
    },
    delete_article() {
      axios
        .delete(lien + "/api/packages/" + this.id_delete)
        .then((reponse) => {
          //console.log(reponse);
          if (reponse.data.status == "true") {
            Swal.fire({
              text: "Article Supprimé",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.showMsg = false;
            setTimeout(() => {
              window.location.reload(true);
            }, 1500);
          }
          if (reponse.data.status == "false") {
            Swal.fire({
              text: "Echec",
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
          }
          // if (
          //   reponse.data.status !== "false" &&
          //   reponse.data.status !== "true"
          // ) {
          //   Swal.fire({
          //     text: "les packages du forunisseur sont déja utilisés donc impossible de le supprimer",
          //     icon: "info",
          //     showConfirmButton: false,
          //     timer: 1500,
          //     timerProgressBar: true,
          //   });
          //   this.showMsg = false;
          // }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: "Echec",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
        });
    },
    getListForPackageAdd(){
    //console.log("this.user.id",this.$store.state.user.id)
      axios
      .all([
      axios.get(lien + "/api/fourn_package/"+ this.$store.state.user.id, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      }),
      axios.get(lien + "/api/ajouts", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      }),
    ])
      .then(res=>{
        //console.log("OBTENIRPACKAGES[0]", res[0].data.data);
        //console.log("OBTENIRPACKAGES[1]", res[1].data.data);
        res[0].data.data.forEach(item=>{
          res[1].data.data.forEach(el=>{
            if(item.id === el.package_id){
              item.stock_ajoute = el.qte
            }else{
              item.stock_ajoute = 0
            }
          })
        })
        this.packages = res[0].data.data;
        //console.log("LIST", this.packages);
        this.isLoading = false;
        setTimeout(function () {
          $("#MyTableData").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
        //console.log("fourn_package[0]",res[0]);
        //console.log("ajouts[1]",res[1]);
      })
      .catch(err=>{
        console.log(err)
      })
    }
  },
  created() {
    this.getListForPackageAdd()
    this.user = this.$store.state.user;
    this.currency = `${this.moneyFormat.format(this.user.solde)} Fcfa`;
    this.isLoading = true;
    
  },
};
</script>
<style scoped>
.detail_packages span{
  text-align: left;
  font-weight:bold;
  font-size:1em;
}
h5{
  text-align:left;
}
.btn-envoyer{
  background: rgb(231, 202, 15) !important;
  color:black !important;
  border:3px solid black !important;
  }
  input,select{ 
    border: 1px solid black !important;
  }
  .form-group{ 
    text-align: left !important;
  }
  .see_detail_package{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.255);
    display: flex;
    justify-content: center;
    place-items: center;
  }
.confirmation_modifie {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.confirmation_modifie .card{
  width:70%;
  padding:1em 0;
}
.bi-pencil-fill{
  cursor:pointer;
}
.float {
  text-align: left;
}
.my_footer {
  position: relative;
  width: 100%;
  bottom: -10em;
  margin-left: 0 !important;
}
.table {
  border: thin solid rgba(139, 139, 139, 0.63) !important;
}
th,
td {
  border: thin solid rgba(141, 140, 140, 0.692) !important;
  text-transform:capitalize;
}
.created_package {
  text-align: left;
  margin-bottom: 1em;
}
.created_package button {
  background: linear-gradient(200deg, rgb(231, 202, 15), rgb(194, 191, 19));
  border: 2px solid black !important;
}

.bg-pen {
  background: rgb(231, 202, 15) !important;
  border: 1px solid black !important;
}
input,
select {
  border: 1px solid black !important;
}
.form-group {
  text-align: left !important;
}
label {
  font-weight: bold !important;
}
.bg-danger {
  background: crimson !important;
  border: 2px solid black !important;
}
.boutons {
  width: 28px !important;
  height: 28px !important;
  display: flex;
  place-items: center;
  justify-content: center;
}
.delete_personne {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.099);
  top: 0;
  left: 0;
  display: flex;
  place-items: center;
  justify-content: center;
}
.delete {
  padding: 3em;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.285);
}
table{
  width:100%;
  overflow: auto;
  font-weight:bold;
}
</style>
